<template>
  <div class="mx-a mb-16">
    <div class="category-wrap">
      <div class="category-header-wrap">약관 설정</div>
      <div class="category-content-wrap">
        <div class="flex w-100">
          <span class="contract-title">서비스 이용약관</span>
          <editor-common :content.sync="customerTermOfService" class="w-100" />
        </div>
        <div class="flex w-100" style="margin-top: 32px">
          <span class="contract-title">개인정보 수집<br/>및 이용동의</span>
          <editor-common :content.sync="customerCollectionOfInfo" class="w-100" />
        </div>
        <div class="flex w-100" style="margin-top: 32px">
          <span class="contract-title">마케팅 수신 동의</span>
          <editor-common :content.sync="customerMaketting" class="w-100" />
        </div>
      </div>
    </div>
    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div>
        <button-common
          :size="SIZE_T"
          :clr="CLR_W"
          wp-cls="mr-8"
          @click="onCancel"
          >취소</button-common
        >
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          :disabled="!checkValid"
          @click="editData"
          >저장</button-common
        >
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "SystemSignAgree",
  mixins: [],
  components: {
    draggable,
  },

  /*** data ***/
  data() {
    return {
      customerTermOfService: "",
      customerCollectionOfInfo: "",
      customerMaketting: "",
      toRoute: {
        path: "",
        meta: "",
      },
      isEdit: false,
    };
  },

  /*** created ***/
  /*** created hook ***/
  created() {},

  /*** mounted ***/
  async mounted() {
    await this.getData();
  },

  // beforeRouteLeave(to, from, next) {
  //   this.toRoute.path = to.fullPath;
  //   this.toRoute.meta = to.meta.id;
  //   if (this.isReset) {
  //     next();
  //   } else {
  //     next(false);
  //     if (!this.isReset) {
  //       this.onPageReset();
  //     }
  //   }
  // },

  /*** computed ***/
  computed: {
    checkValid() {
      return this.customerTermOfService && this.customerCollectionOfInfo && this.customerMaketting;
    },
  },

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      const rs = await this.$axios({
        url: "/admin/system/customer/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.customerTermOfService = rs.resultData.customerTermOfService ? rs.resultData.customerTermOfService : "";
        this.customerCollectionOfInfo = rs.resultData.customerCollectionOfInfo ? rs.resultData.customerCollectionOfInfo : "";
        this.customerMaketting = rs.resultData.customerMaketting
          ? rs.resultData.customerMaketting
          : "";
      } else {
        console.log("소비자 약관 조회 실패");
      }
    },
    async editData() {
      const rs = await this.$axios({
        url: "/admin/system/customer/update",
        params: {
          customerTermOfService: this.customerTermOfService,
          customerCollectionOfInfo: this.customerCollectionOfInfo,
          customerMaketting: this.customerMaketting,
        },
      });
      if (rs.result === "SUC") {
        this.isEdit = false;
        await this.openConfirm();
        await this.getData();
      } else {
        console.log("소비자 약관 수정 실패");
      }
    },
    async openConfirm() {
      await this.$openAlert(
        {
          bodyTx: "변경된 내용이 저장되었습니다.",
          btnScndNm: "확인",
        },
        this
      );
    },
    onEdit() {
      this.isEdit = true;
    },
    onCancel() {
      this.isEdit = false;
    },
  },
};
</script>

<style scoped lang="scss">
.category-wrap {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  border: 1px solid #d8dae5;
}
.category-header-wrap {
  width: 100%;
  border-bottom: 1px solid #d8dae5;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  color: #515466;
}
.category-content-wrap {
  padding: 50px 32px 80px 32px;
}
.contract-title {
  min-width: 150px;
  color: #828699;
  font-size: 14px;
  font-weight: 500;
}
::v-deep .ql-editor {
  height: 278px !important;
  overflow: auto;
}
</style>
